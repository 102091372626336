import { CmeLayout } from "./LNTransferDetailsSteps/CmeLayout"
import { CmeMaterialColor } from "./LNTransferDetailsSteps/CmeMaterialColor"
import { CmeProof } from "./LNTransferDetailsSteps/CmeProof"
import { CmeQuantity } from "./LNTransferDetailsSteps/CmeQuantity"
import { CmeSewDisk } from "./LNTransferDetailsSteps/CmeSewDisk"
import { CmeSize } from "./LNTransferDetailsSteps/CmeSize"
import { CmeSplit } from "./LNTransferDetailsSteps/CmeSplit"
import { CmeTails } from "./LNTransferDetailsSteps/CmeTails"
import { CmeTextFont } from "./LNTransferDetailsSteps/CmeTextFont"

export const LNTransferDetailsGenerator: {[key:string]:{GetDetails:(wizardData:any)=>{name:string, detail:string}[]}} = {
  CustomMadeEasyCadCut: {
    GetDetails(wizardData:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1439
      return [
        CmeProof(wizardData),
        CmeSize(wizardData),
        CmeTextFont(wizardData),
        CmeMaterialColor(wizardData),
        CmeLayout(wizardData),
        CmeTails(wizardData),
        CmeSplit(wizardData),
        CmeQuantity(wizardData)
      ]
    },
  },
  CustomMadeEasyStandard: {
    GetDetails(wizardData:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1516
      return [
        CmeProof(wizardData),
        CmeSize(wizardData),
        CmeTextFont(wizardData),
        CmeMaterialColor(wizardData),
        CmeLayout(wizardData),
        CmeTails(wizardData),
        CmeSplit(wizardData),
        CmeSewDisk(wizardData),
        CmeQuantity(wizardData)
      ]
    },
  },
  CustomMadeEasyKissCut: {
    GetDetails(wizardData:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1465
      return [
        CmeProof(wizardData),
        CmeSize(wizardData),
        CmeTextFont(wizardData),
        CmeMaterialColor(wizardData),
        CmeLayout(wizardData),
        CmeTails(wizardData),
        CmeSplit(wizardData),
        CmeSewDisk(wizardData),
        CmeQuantity(wizardData)
      ]
    },
  },
  CustomMadeEasySimStitch: {
    GetDetails(wizardData:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1491
      return [
        CmeProof(wizardData),
        CmeSize(wizardData),
        CmeTextFont(wizardData),
        CmeMaterialColor(wizardData),
        CmeLayout(wizardData),
        CmeTails(wizardData),
        CmeSplit(wizardData),
        CmeQuantity(wizardData)
      ]
    },
  },
  CustomMadeEasySublimatedTwill: {
    GetDetails(wizardData:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1573
      return [
        CmeProof(wizardData),
        CmeSize(wizardData),
        CmeTextFont(wizardData),
        CmeMaterialColor(wizardData),
        CmeLayout(wizardData),
        CmeTails(wizardData),
        CmeSplit(wizardData),
        CmeSewDisk(wizardData),
        CmeQuantity(wizardData)
      ]
    },
  },
  CustomMadeEasySublimatedSimStitch: {
    GetDetails(wizardData:any) {//https://github.com/Stahls/stahls-wizards/blob/e899b31ecb5341c348b9eb66cc01e2574516b15c/src/letters-numbers/config/cme-block-wizard-config.js#L1542
      return [
        CmeProof(wizardData),
        CmeSize(wizardData),
        CmeTextFont(wizardData),
        CmeMaterialColor(wizardData),
        CmeLayout(wizardData),
        CmeTails(wizardData),
        CmeSplit(wizardData),
        CmeQuantity(wizardData)
      ]
    },
  },
}
