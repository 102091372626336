import { Header } from "../../components/list/header"
import { Pager } from "../../components/list/pager"
import { ITEMS_PER_PAGE, DEFAULT_IMAGE } from "../../config"
import { convertDesignDocToImageObject, generateImageSrcFromArtwork } from "../../utils/general"
import SkeletonLoader from "../../components/list/skeletonAnimation"
import { getColor } from "../../utils/general"
import { getUrlParams } from "../../utils/general"
import { useSnapshot } from "valtio"
import appModel from "../../models/app-model"
import { addToCart, redirectToLNTransfer } from "../../utils/cart"
import { DesignDoc } from "../../types/api-types"
import { useEffect, useState } from "react"
import { updateDashboard } from "../../models/app-actions"
import { ArtStates, InternalArtStates, toArtState, InternalArtStatesDescriptions } from "../../types/art-status"
import { productMapping } from "../../types/product-types"
import NoResults from "../../components/noResults/noResults"
import { ArtworkItemType } from "../../types/api-types"
import { getImageObject } from "../../utils/general";
import Spinner from "../design-detail/comps/spinner"
import formatDateString from "../../utils/formatted-date"

// Styles
import statesStyles from "./art-filter.module.css";
import styles from './art.module.css';

export function DesignList() {
  const appSnap = useSnapshot(appModel);
  const docs = appSnap.designDocs as DesignDoc[];
  const artStatusFilter = appSnap.artStatusFilter
  const totalItems = appSnap.totalDesigns;
  const isLoading = appSnap.isPageLoading;
  const loaderArray = [...Array(12).keys()];
  const urlParams = getUrlParams();
  const customerId = appSnap.customerId || "";
  const searchText = appSnap.searchText || "";
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);
  const archived = appSnap.artStatusFilter == ArtStates.ARCHIVED;
  const [processingItemId, setProcessingItemId] = useState<string | null>(null);

  const handleAddToCart = async (itemId: string, customerId: string, docType: string, wizardType: string, isLNProof: boolean, statusID: string) => {
    try {
      addToCart(itemId, customerId, docType, wizardType, isLNProof, statusID);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    //load the list of artwork
    updateDashboard({ archived })
  }, [archived]);

  const handleMenuClick = (value: boolean) => {
    setSideBarOpen(value);
  }

  const handleEditOrder = async (itemId: string, wizardType: string) => {
    try {
      setProcessingItemId(itemId);
      await redirectToLNTransfer(itemId, customerId, wizardType, false, false);
    } catch (err) {
      console.log(err);
    } finally {
      setProcessingItemId(null);
    }
  };

  return (
    <div className={sideBarOpen ? styles.appContainer : ""}>
      {sideBarOpen && <div className={styles.overlay}></div>}
      <div className={styles.wrapper}>
        <Header customerId={customerId} onMenuToggle={(e) => handleMenuClick(e)} />

        <Pager
          totalItems={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          currentState={artStatusFilter}
          searchText={searchText}
        />

        {!isLoading && docs.length === 0 && (
          <NoResults
            isSearch={searchText !== ''}
            searchText={searchText}
            artStatusFilter={artStatusFilter}
          />
        )}

        <ul role="list" className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-4 md:grid-cols-3 md:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {!isLoading ? docs?.map((doc: DesignDoc) => {
            const { wizardID, wizardData, statusID } = doc;
            const statusName = InternalArtStatesDescriptions[statusID].description;
            const isCustomProduct = wizardData.serviceType == "CustomProduct"
            const productType = isCustomProduct ? "Uploaded file" : wizardData && (productMapping[wizardID] ?? (productMapping[wizardData.productType] ?? wizardData.productType));
            const artName = wizardData && wizardData.artName;
            const statusColor = getColor(toArtState(statusID)) ?? "";
            const canAddToCart = (statusID === InternalArtStates.APPROVED || statusID === InternalArtStates.REVIEW) && !archived;
            const artworkTypes = convertDesignDocToImageObject(doc);
            const isBanner = wizardID === "Banners";
            const height = wizardData && parseFloat(wizardData.artHeight);
            const width = wizardData && parseFloat(wizardData.artWidth);
            const fontHeight = wizardData && parseFloat(wizardData?.fontHeight);
            const bannerWidth = isBanner ? parseFloat(wizardData.bannerWidth) : undefined;
            const bannerHeight = isBanner ? parseFloat(wizardData.bannerHeight) : undefined;
            const imageObjects = getImageObject(artworkTypes, DEFAULT_IMAGE);
            const imgSrc = Array.isArray(imageObjects) ? imageObjects[0] : DEFAULT_IMAGE;
            const imgUrl = generateImageSrcFromArtwork(imgSrc as ArtworkItemType, DEFAULT_IMAGE)
            const isLNProof = doc?.isLNProof ?? false;
            const canEditAndOrder = !isLNProof && doc?.docType === "LNTransfer";
            const addedToCartDate = doc?.addedToCartDate;
            const formattedDate = addedToCartDate ? formatDateString({ dateString: addedToCartDate }) : "";

            const size = {
              height: fontHeight || (isBanner ? bannerHeight : height) || null,
              width: fontHeight ? null : (isBanner ? bannerWidth : width) || null,
            };

            return (
              <li key={doc.id} className="relative p-1">
                <div className={styles.card}>
                  <a href={`?artId=${doc.id}&${urlParams}`} className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                    <img src={imgUrl} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                    <button type="button" className={`absolute inset-0 focus:outline-none`}>
                      <span className="sr-only">View details for {doc.id}</span>
                    </button>
                  </a>
                  <div className={[styles.statusBar, (statusColor ? statesStyles[statusColor] : "")].join(' ')}></div>

                  <div className={styles.cardBody}>
                    <div className={styles.cardDetails}>
                      <span><b>Created On:</b></span>
                      <span>{formattedDate ?? "--"}</span>
                    </div>
                    {artStatusFilter === ArtStates.ALL &&
                      <div className={styles.cardDetails}>
                        <span><b>Status:</b></span>
                        <span>{statusName}</span>
                      </div>
                    }
                    <div className={styles.cardDetails}>
                      <span><b>Artwork Name:</b></span>
                      <span>{artName ?? "--"}</span>
                    </div>
                    <div className={styles.cardDetails}>
                      <span><b>Design ID:</b></span>
                      <span>{doc.id ?? "--"}</span>
                    </div>
                    <div className={styles.cardDetails}>
                      <span><b>Size:</b></span>
                      <span>{size?.width ?? "--"} x {size?.height ?? "--"} </span>
                    </div>
                    <div className={styles.cardDetails}>
                      <span><b>Product Type:</b></span>
                      <span>{productType ?? "--"}</span>
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-3 p-2 pb-6">
                    <a href={canAddToCart ? "#" : `?artId=${doc.id}&${urlParams}`} onClick={(e) => {
                      if (canAddToCart) {
                        e.preventDefault();
                        if (doc.id) {
                          handleAddToCart(doc.id, customerId, doc.docType, doc.wizardID, doc.isLNProof ?? false, doc.statusID);
                        }
                      }
                    }}>
                      <div className={styles.dashBtnWrapper}>
                        <button
                          type="button"
                          className={`${styles.orangeBtn} flex flex-row gap-1 text-white hover:bg-blue-500`}>
                          {canAddToCart ? "Add to Cart" : "Review"}
                        </button>
                        {canEditAndOrder && (
                          <button
                            disabled={processingItemId === doc.id}
                            type="button"
                            className={`${styles.orangeBtn} ${styles.editBtn} flex flex-row gap-1 text-white hover:bg-blue-500`}
                            onClick={() => handleEditOrder(doc.id, doc.wizardID)}
                          >
                            {processingItemId === doc.id
                              ? <Spinner spinning={true} color="#F36E29" size={{ width: "22px", height: "22px" }} />
                              : "Edit"}
                          </button>
                        )}
                      </div>
                    </a>
                  </div>
                </div>
              </li>
            );
          })
            :
            loaderArray?.map((i) => (
              <li key={i} className="relative">
                <SkeletonLoader />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}